
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class Feedback extends Vue {
  @Prop() readonly auditShow!: boolean;
  @Prop() readonly orderId!: string;
  private info = {
    handleExplain: '',
    orderApprove: null
  }

  private rules = {
    orderApprove: [
      { required: true, message: '请选择审核结果', trigger: 'change' }
    ]
  }

  private submitShow = false

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.newOrder.insertFlowOrderHandle, {
          handleType: '3',
          ...this.info,
          orderId: this.orderId
        }).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 关闭
  closeDialog () {
    this.$emit('update:auditShow', false)
    this.info = {
      handleExplain: '',
      orderApprove: null
    }
    ;(this.$refs.info as ElForm).clearValidate()
  }
}
