
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { ProjectUser } from '@/types/project'

@Component
export default class Transfer extends Vue {
  @Prop() readonly transferShow!: boolean;
  @Prop() readonly orderId!: string;
  @Prop() readonly projectId!: string;
  // 获取项目下所有人信息
  @Watch('projectId', { immediate: true })
  getProjectUserList (val: string) {
    if (val !== '') {
      this.$axios.get(this.$apis.project.selectProjectUserByList, {
        projectId: this.projectId
      }).then((res: { list: Array<ProjectUser>; total: number }) => {
        this.projectUserList = res.list || []
      })
    }
  }

  private projectUserList: Array<ProjectUser> = []
  private info = {
    forwardUser: '',
    handleExplain: ''
  }

  private rules = {
    forwardUser: [
      { required: true, message: '请选择转办人', trigger: 'change' }
    ]
  }

  private submitShow = false

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.newOrder.insertFlowOrderHandle, {
          handleType: '1',
          ...this.info,
          orderId: this.orderId
        }).then(() => {
          this.$message.success('转办成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 关闭
  closeDialog () {
    this.$emit('update:transferShow', false)
    this.info = {
      forwardUser: '',
      handleExplain: ''
    }
    ;(this.$refs.info as ElForm).clearValidate()
  }
}
