
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class Close extends Vue {
  @Prop() readonly closeShow!: boolean;
  @Prop() readonly orderId!: string;
  private info = {
    handleExplain: ''
  }

  private submitShow = false

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.newOrder.insertFlowOrderHandle, {
          handleType: '4',
          ...this.info,
          orderId: this.orderId
        }).then(() => {
          this.$message.success('关闭成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 关闭
  closeDialog () {
    this.$emit('update:closeShow', false)
    this.info.handleExplain = ''
    ;(this.$refs.info as ElForm).clearValidate()
  }
}
