
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'
import { minLength5 } from '@/utils/validate'

@Component({
  components: { UploadFile }
})
export default class Feedback extends Vue {
  @Prop() readonly feedbackShow!: boolean;
  @Prop() readonly orderId!: string;
  private info: { fileList: Array<FileInfo>; handleExplain: string } = {
    fileList: [],
    handleExplain: ''
  }

  private rules = {
    handleExplain: [
      { required: true, message: '请输入反馈说明', trigger: 'change' },
      { validator: minLength5, message: '请至少输入5个字', trigger: 'change' }
    ]
  }

  private submitShow = false

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.fileList.push(file)
  }

  imgRemove (index: number) {
    this.info.fileList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.newOrder.insertFlowOrderHandle, {
          handleType: '2',
          ...this.info,
          orderId: this.orderId
        }).then(() => {
          this.$message.success('反馈成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 关闭
  closeDialog () {
    this.$emit('update:feedbackShow', false)
    this.info = {
      fileList: [],
      handleExplain: ''
    }
    ;(this.$refs.info as ElForm).clearValidate()
  }
}
